$red: #FF233D;
$green: #1aeb61;
$black: #000;
$dark-gray-1: #161618;
$dark-gray-2: #202020;
$dark-gray-3: #333;
$dark-gray-4: #444;
$gray-1: #aaa;
$gray-2: #999;
$gray-3: #777;
$light-gray-1: #ddd;
$light-gray-2: #e5e5e5;
$light-gray-3: #f5f5f5;
$light-gray-4: #fbfbfb;
$white: #fff;

$primary: $red;
$danger: $red;
$secondary: $green;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "danger":  $danger,
) !default;

$font-family-base: 'Pretendard', system-ui, -apple-system, arial !default;
$body-color: $black;

$grid-gutter-width: 1.25rem !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px,
  xxl: 1336px,
) !default;

$header-height: 60px;
$header-height-lg: 70px;
$header-height-lg-expand: 120px;

$container-max-width: 1336px !default;

$header-z-index: 99;